<template>
  <div class="dp-section">
    <h2 v-if="titleEnText || titleJpText" class="dp-section__title">
      <span v-if="titleEn" :class="titleEnClass">
        {{ titleEnText }}
        <button
          v-if="!isBrewing && titleEn === 'TASTE' && isProRecipeDetail"
          type="button"
          v-ripple="$utilities.getRippleOptions('light')"
          @click="onClickTasteHelp">
          <Icon
            name="dpHelpCircleR"
            color="dpBlack01"
            :width="21"
            :height="21" />
        </button>
      </span>

      <span v-if="titleJp" class="dp-section__title-jp">
        {{ titleJpText }}
      </span>

      <span v-if="taste" class="dp-section__tag">
        <DripPodTasteTag :type-id="taste" />
      </span>
    </h2>

    <slot />

    <DpTextButton v-if="linkLabel" align="right" fluid>
      <template v-slot:iconAppend>
        <Icon name="dpArrowRightB" color="dpBlack01" />
      </template>
      {{ linkLabel }}
    </DpTextButton>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue'),
    DripPodTasteTag: () => import('@/components/dripPod/proRecipe/TasteTag.vue')
  },

  props: {
    titleEn: {
      type: String,
      default: ''
    },

    // lg | ''
    titleEnSize: {
      type: String,
      default: ''
    },

    titleJp: {
      type: String,
      default: ''
    },

    taste: {
      type: String,
      default: ''
    },

    linkLabel: {
      type: String,
      default: ''
    }
  },

  setup: (props, context) => {
    const titleEnText = computed(() => (props.titleEn ? props.titleEn : ''));
    const titleJpText = computed(() => (props.titleJp ? props.titleJp : ''));

    const titleEnClass = computed(() => {
      return [
        'dp-section__title-en',
        {
          'dp-section__title-en--lg': props.titleEnSize === 'lg'
        }
      ];
    });

    const isProRecipeDetail = computed(
      () => context.root.$route.name === 'DripPodProRecipeDetail'
    );

    // 抽出中か
    const isBrewing = computed(
      () => context.root.$route.name === 'DripPodProRecipeBrewing'
    );

    const onClickTasteHelp = () => {
      // eslint-disable-next-line no-console
      context.root.$router.push({
        name: 'DripPodProRecipeTasteMapOverview',
        query: {
          proRecipeId: context.root.$route?.params.proRecipeId
        }
      });
    };

    return {
      WORDS,
      titleEnText,
      titleJpText,
      titleEnClass,
      isProRecipeDetail,
      isBrewing,
      onClickTasteHelp
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-section {
  padding: 0 16px 16px;

  &:first-of-type {
    padding-top: 0;
  }

  &:not(:last-of-type) {
    border-bottom: none;
  }

  & + .dp-section {
    padding-top: 16px;
  }

  .dp-section {
    padding-right: 0;
    padding-left: 0;
  }

  &__title {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 16px;
    margin-bottom: 8px;

    &:has(.dp-section__title-en--lg) {
      margin-bottom: 16px;
    }

    &-en {
      @include mixin.dpEnTitle;

      place-self: center left;
      font-size: 24px;

      &--lg {
        font-size: 32px;
      }
    }

    &-jp {
      place-self: center right;
      color: variables.$dpGrey44;
      font-weight: normal;
      font-size: 12px;
    }
  }

  &__tag {
    place-self: center end;
  }
}
</style>
